$checkbox-radius: $radius !default;
$checkbox-border: 0.1rem solid $grey-lighter !default;
$checkbox-block-background: $white-ter !default;
$checkradio-focus: 1px dotted $grey-light !default;
$checkradio-top-offset: 0rem !default;

@mixin checkbox-size($size) {
  $newSize: $size * 1.5;
  $height: math.div($newSize, 2.5);
  $width: math.div($newSize, 4);

  + label {
    font-size: $size;

    // line-height: $newSize
    padding-left: $size * 2;

    &::before,
    &:before {
      width: $newSize;
      height: $newSize;
    }

    &::after,
    &:after {
      width: $width;
      height: $height;
      top: (math.div($newSize, 2) - math.div($height, 2)) * 0.9;
      left: $height;
    }
  }

  &.is-block {
    + label {
      &::after,
      &:after {
        top: (math.div($newSize, 2) - math.div($height, 2)) * 1.3;
        left: $height * 1.3;
      }
    }
  }

  &.is-rtl {
    + label {
      padding-left: 0;
      padding-right: $size * 2;

      &::after,
      &:after {
        left: auto;
        right: $height;
      }
    }
  }
}

// Use a mixin to define all ratios in the same spot
@mixin radio-size($size) {
  $newSize: $size * 1.5;
  $height: $newSize;
  $width: $newSize;

  + label {
    font-size: $size;
    line-height: $newSize;
    padding-left: $size * 2;

    &::before,
    &:before,
    &::after,
    &:after {
      width: $width;
      height: $height;
    }
  }

  &.is-rtl {
    + label {
      padding-right: $size * 2;
    }
  }
}

.is-checkradio[type="radio"],
.is-checkradio[type="checkbox"] {
  outline: 0;
  user-select: none;
  display: inline-block;
  position: absolute;
  opacity: 0;

  + label {
    position: relative;
    display: initial;
    cursor: pointer;
    vertical-align: middle;
    margin: 0.5em;
    padding: 0.2rem 0.5rem 0.2rem 0;
    border-radius: $checkbox-radius;

    &:hover {
      &::before,
      &:before {
        animation-duration: 0.4s;
        animation-fill-mode: both;
        animation-name: hover-color;
      }
    }

    &::before,
    &:before {
      position: absolute;
      left: 0;
      top: $checkradio-top-offset;
      content: "";
      border: $checkbox-border;
    }

    &::after,
    &:after {
      position: absolute;
      display: none;
      content: "";
      top: $checkradio-top-offset;
    }
  }

  &.is-rtl {
    + label {
      margin-right: 0rem;
      margin-left: 0.5rem;

      &::before,
      &:before {
        left: auto;
        right: 0;
      }
    }
  }

  &:focus {
    + label {
      &::before,
      &:before {
        outline: $checkradio-focus;
      }
    }
  }

  &:hover:not([disabled]) {
    + label {
      &::before,
      &:before {
        border-color: $primary !important;
      }
    }
  }

  &:checked {
    + label {
      &::before,
      &:before {
        border: $checkbox-border;
      }
    }

    &[disabled] {
      cursor: not-allowed;

      + label {
        opacity: 0.5;
      }
    }

    + label {
      &::before,
      &:before {
        animation-name: none;
      }

      &::after,
      &:after {
        display: inline-block;
      }
    }
  }

  &[disabled] {
    cursor: not-allowed;

    + label {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover,
      &:before,
      &::before,
      &::after,
      &:after {
        cursor: not-allowed;
      }
    }

    &:hover {
      cursor: not-allowed;

      &::before,
      &:before {
        animation-name: none;
      }
    }

    &::before,
    &:before {
      cursor: not-allowed;
    }

    &::after,
    &:after {
      cursor: not-allowed;
    }
  }

  &.has-no-border {
    + label {
      &::before,
      &:before {
        border: none !important;
      }
    }
  }

  &.is-block {
    display: none !important;

    + label {
      width: 100% !important;
      background: $checkbox-block-background;
      color: findColorInvert($checkbox-block-background);
      padding-right: 0.75em;

      &::before,
      &:before {
        border: none !important;
      }
    }
  }
}

.is-checkradio[type="checkbox"] {
  + label {
    &::before,
    &:before {
      border-radius: $checkbox-radius;
    }

    &::after,
    &:after {
      box-sizing: border-box;
      transform: rotate(45deg);
      border-width: 0.1rem;
      border-style: solid;
      border-color: $primary;
      border-top: 0;
      border-left: 0;
    }
  }

  &.is-circle {
    + label {
      &::before,
      &:before {
        border-radius: 50%;
      }
    }
  }

  @include checkbox-size($size-normal);

  &.is-small {
    @include checkbox-size($size-small);
  }

  &.is-medium {
    @include checkbox-size($size-medium);
  }

  &.is-large {
    @include checkbox-size($size-large);
  }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      &.has-background-color {
        + label {
          &::before,
          &:before {
            border-color: transparent !important;
            background-color: $color !important;
          }
        }
      }

      &:hover:not([disabled]) {
        + label {
          &::before,
          &:before {
            border-color: $color !important;
          }
        }
      }

      &:checked {
        + label {
          &::after,
          &:after {
            border-color: $color !important;
          }
        }

        &.has-background-color {
          + label {
            &::before,
            &:before {
              border-color: transparent !important;
              background-color: $color !important;
            }

            &::after,
            &:after {
              border-color: $color-invert !important;
              background-color: $color !important;
            }
          }
        }

        &.is-block {
          + label {
            color: $color-invert;
            border-color: $color !important;
            background: $color;

            &::after,
            &:after {
              border-color: $color-invert !important;
            }
          }
        }
      }
    }
  }

  &:indeterminate {
    + label {
      &::after,
      &:after {
        display: inline-block;
        transform: rotate(90deg);
        border-bottom: none;
      }
    }

    @each $name, $pair in $colors {
      $color: nth($pair, 1);
      $color-invert: nth($pair, 2);

      &.is-#{$name} {
        + label {
          &::after,
          &:after {
            border-color: $color;
          }
        }
      }
    }
  }
}

.is-checkradio[type="radio"] {
  + label {
    &::before,
    &:before {
      border-radius: 50%;
    }

    &::after,
    &:after {
      border-radius: 50%;
      background: $primary;
      left: 0;
      transform: scale(0.5);
    }
  }

  &:checked {
    &.has-background-color {
      + label {
        &::before,
        &:before {
          border-color: $text !important;
          background-color: $text !important;
        }

        &::after,
        &:after {
          border-color: $text !important;
          background-color: $text !important;
        }
      }
    }
  }

  &.is-rtl {
    + label {
      padding-left: 0;

      &::after,
      &:after {
        left: auto;
        right: 0;
      }
    }
  }

  @include radio-size($size-normal);

  &.is-small {
    @include radio-size($size-small);
  }

  &.is-medium {
    @include radio-size($size-medium);
  }

  &.is-large {
    @include radio-size($size-large);
  }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      &.has-background-color {
        + label {
          &::before,
          &:before {
            border-color: $color !important;
            background-color: $color !important;
          }
        }
      }

      &:hover:not([disabled]) {
        + label {
          &::before,
          &:before {
            border-color: $color !important;
          }
        }
      }

      &:checked {
        + label {
          &::after,
          &:after {
            border-color: $color !important;
            background-color: $color !important;
          }
        }

        &.has-background-color {
          + label {
            &::before,
            &:before {
              border-color: $color !important;
              background-color: $color !important;
            }

            &::after,
            &:after {
              border-color: $color-invert !important;
              background-color: $color-invert !important;
            }
          }
        }
      }
    }
  }
}
