.c-sideNavigationLabel {
  display: flex;
  align-items: center;
}

.c-sideNavigationLabel__wrapper {
  padding: 2px 4px;
  font-size: 10px;
  font-weight: 700;
  border-radius: 4px;
}

.c-sideNavigationLabel__warning {
  background-color: #f57a00;
  color: #ffffff;
}
