.p-productItems {
  .p-productItems__checkbox:checked + .p-productItems__checkboxLabel {
    background: linear-gradient(0deg, rgba(51, 102, 153, 0.15), rgba(51, 102, 153, 0.15)), linear-gradient(0deg, #ffffff, #ffffff);
  }

  .p-productItems__checkbox:checked + .p-productItems__checkboxLabel::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 15px;
    border: solid rgba(51, 102, 153);
    border-width: 0 3px 3px 0;
    top: 10px;
    right: 18px;
    transform: rotate(45deg);
  }

  .p-productItems__checkedList {
    border-bottom: 1px solid #f5f5f5;
  }

  .p-productItems__checked {
    padding-left: 29px;
    position: relative;
    display: inline-flex;
    align-items: center;
  }

  .p-productItems__checked::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0; /* 丸印の中央にチェックマークを配置するための位置調整 */
    transform: translateY(-50%);
    width: 24px; /* 丸印の幅 */
    height: 24px; /* 丸印の高さ */
    border: 2px solid #48c774; /* 黒色のボーダー */
    border-radius: 50%; /* 丸い形状にする */
  }

  .p-productItems__checked::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 14px;
    border: solid #48c774;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    left: 8px; /* チェックマークの左位置を調整 */
  }
}
