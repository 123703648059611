@for $i from 0 through 4 {
  .u-mt#{$i * 5} {
    margin-top: #{$i * 5}px;
  }
  .u-mb#{$i * 5} {
    margin-bottom: #{$i * 5}px;
  }
  .u-ml#{$i * 5} {
    margin-left: #{$i * 5}px;
  }
  .u-mr#{$i * 5} {
    margin-right: #{$i * 5}px;
  }
  .u-pt#{$i * 5} {
    padding-top: #{$i * 5}px;
  }
  .u-pb#{$i * 5} {
    padding-bottom: #{$i * 5}px;
  }
  .u-pl#{$i * 5} {
    padding-left: #{$i * 5}px;
  }
  .u-pr#{$i * 5} {
    padding-right: #{$i * 5}px;
  }

  .u-mti#{$i * 5} {
    margin-top: #{$i * 5}px !important;
  }
  .u-mbi#{$i * 5} {
    margin-bottom: #{$i * 5}px !important;
  }
  .u-mli#{$i * 5} {
    margin-left: #{$i * 5}px !important;
  }
  .u-mri#{$i * 5} {
    margin-right: #{$i * 5}px !important;
  }
  .u-pti#{$i * 5} {
    padding-top: #{$i * 5}px !important;
  }
  .u-pbi#{$i * 5} {
    padding-bottom: #{$i * 5}px !important;
  }
  .u-pli#{$i * 5} {
    padding-left: #{$i * 5}px !important;
  }
  .u-pri#{$i * 5} {
    padding-right: #{$i * 5}px !important;
  }
}

.reverse-row-order {
  flex-direction: row-reverse;
}

.farmer-head-control-buttons {
  margin-top: 10px;
  margin-bottom: 10px;
  width: auto;
  max-width: auto;

  .control-button {
    width: 100%;
  }
}

.col {
  &.is-one-third {
    width: math.div(100%, 3);
  }
  &.is-one-fifth {
    width: 20%;
  }
  &.is-pc-only {
    @media screen and (max-width: 770px) {
      &.is-one-third {
        width: auto;
      }
      &.is-one-fifth {
        width: auto;
      }
    }
  }
}

.farmer-listing-control-panel {
  background-color: whitesmoke;
}

h1.title {
  font-size: 1.6rem;
}
