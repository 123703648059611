// スマホ用のヘッダーとサイドバーが両方出ないようなレスポンシブ幅
$pc-width: 1087;
$sp-width: 768;

@mixin min-breakpoint($point) {
  @media screen and (min-width: ($point + 1) + "px") {
    @content;
  }
}
@mixin max-breakpoint($point) {
  @media screen and (max-width: $point + "px") {
    @content;
  }
}
@mixin pc-breakpoint() {
  @media screen and (max-width: $pc-width+"px") {
    @content;
  }
}
.p- {
  &dashboard {
    background-color: #fafafa;
    &_ttl {
      font-size: 26px;
      margin: 18px;
      @include min-breakpoint($pc-width) {
        margin: 18px 0;
      }
      @include min-breakpoint($sp-width) {
        margin: 0 0 18px;
      }
    }
    &_ttlNotice {
      font-size: 14px;
      font-weight: bold;
    }
    &_container {
      background-color: white;
    }
  }
  &dashboardSec {
    margin-bottom: 40px;
    &_header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      height: auto;
      min-height: 56px;
      padding: 0 14px;
      border-left: 3px solid #4a4a4a;
    }
    &_notitle {
      border-left: none;
      padding-top: 10px;
    }
    &_select {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      height: auto;
      min-height: 56px;
      padding: 0 14px;
    }
    &_ttl {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      font-size: 22px;
      font-weight: bold;
    }
    &_ttlNotice {
      font-size: 14px;
      font-weight: normal;
    }
    &_ttlSelect {
      flex: 0 0 240px;
      height: 40px;
      margin: 5px 0;
    }
    &_container {
      display: flex;
      flex-wrap: wrap;
      padding: 18px 14px;
      &-salesChart {
        position: relative;
        left: -20px;
        @include min-breakpoint($sp-width) {
          left: 0px;
        }
      }
      &-updateDate {
        justify-content: flex-end;
      }
    }
    &_pagination {
      width: 100%;
      position: relative;
      margin-top: -20px;
      padding: 0 14px;
      @include min-breakpoint($pc-width) {
        margin-top: 0;
        padding: 0 50px;
      }
    }
  }
  &navPagnation {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.c- {
  &select {
    position: relative;
    > select {
      display: block;
      width: 100%;
      height: 100%;
      max-width: 100%;
      padding: 0 14px;
      appearance: none;
      background-color: white;
      border-color: #dbdbdb;
      border-radius: 4px;
      color: #363636;
      cursor: pointer;
      font-size: 16px;
      outline: none;
    }
    &::after {
      display: block;
      content: "";
      height: 0.625em;
      margin-top: -0.4375em;
      border: 3px solid transparent;
      border-color: #369;
      z-index: 4;
      border-radius: 2px;
      border-right: 0;
      border-top: 0;
      pointer-events: none;
      position: absolute;
      top: 50%;
      right: 14px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transform-origin: center;
      transform-origin: center;
      width: 0.625em;
    }
  }

  &dashboardCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 14px;
    padding: 12px 24px;
    border: 1px solid #d8d8d8;
    &_dl {
      flex: 0 0 195px;
      > dt {
        font-size: 16px;
        font-weight: bold;
      }
    }
    &_dtNotice {
      font-size: 14px;
      font-weight: normal;
    }
    &_detail {
      min-width: 115px;
    }
    &_detailList {
      width: 100%;
      > li {
        font-size: 14px;
      }
    }
    &_detailDl {
      display: flex;
      flex-wrap: wrap;
      > dt {
        margin-right: 0.5em;
      }
    }
    &_detailUnit {
      font-size: 20px;
    }
    &_value {
      white-space: nowrap;
      font-size: 20px;
      font-weight: bold;
    }
    &_num {
      font-size: 26px;
    }
    &_link {
      color: #369;
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
      &:hover,
      &:active,
      &:visited,
      &:link {
        color: #369;
      }
      &-larger {
        font-size: 16px;
      }
    }
    &-2cols {
      flex: 0 0 100%;
      margin: 0 0 14px;
      @include min-breakpoint($pc-width) {
        flex: 0 0 calc(50% - 7px);
      }
      &:not(:nth-child(2n)) {
        margin-right: 0;
        @include min-breakpoint($pc-width) {
          margin-right: 14px;
        }
      }
    }
  }
  &btnArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    text-size-adjust: 50%;
    background-color: white;
    border: 2px solid #3f6695;
    border-radius: 4px;
    cursor: pointer;
    &:disabled {
      background-color: #ededed;
      border-color: #ccc;
      cursor: not-allowed;
      &::before {
        border-color: #ccc;
      }
    }
    &::before {
      display: block;
      content: "";
      width: 8px;
      height: 8px;
      position: relative;
      border-width: 2px;
      border-color: #3f6695;
      border-style: solid solid none none;
    }
    &-prev {
      &:before {
        left: 2px;
        transform: rotate(-135deg);
      }
    }
    &-next {
      &:before {
        right: 2px;
        transform: rotate(45deg);
      }
    }
  }
  &feedbackScore {
    width: 100%;
    display: flex;
    flex-flow: column;
    margin-bottom: 10px;
    &_half {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      flex-flow: row;
      @include pc-breakpoint() {
        flex-flow: column;
      }
    }
    &_onethird {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      flex-flow: row;
      @include pc-breakpoint() {
        flex-flow: column;
      }
    }
    &_section {
      border: 1px solid #d8d8d8;
      justify-content: center;
      display: flex;
      &_point {
        min-width: 380px;
        display: flex;
        &_detail {
          &_title {
            font-size: 20px;
            font-weight: 700;
            margin-top: 33px;
            text-align: center;
            line-height: 1;
            display: inline-block;
          }
          &_rank {
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            img {
              position: relative;
              top: 11px;
              right: 5px;
            }
            b {
              font-size: 45px;
            }
          }
          &_star {
            text-align: center;
            margin-top: 10px;
            margin-bottom: 32px;
            @include pc-breakpoint() {
              margin-bottom: 0;
            }
            img {
              margin: 0 2px;
            }
          }
        }
        &_graph {
          width: 380px;
          ul {
            margin-top: 30px;
            li {
              display: flex;
              justify-content: flex-start;
              font-size: 14px;
              color: #7a7a7a;
              span {
                font-size: 18px;
                color: #ebd53e;
                font-weight: 900;
                position: relative;
                top: -2px;
                width: 28px;
              }
              img {
                margin-right: 2px;
              }
              @include pc-breakpoint() {
                justify-content: center;
              }
            }
          }
          @include pc-breakpoint() {
            width: 100%;
            margin-bottom: 30px;
          }
          &_star {
            background-color: #eaeaea;
            height: 9.6px;
            width: 175px;
            position: relative;
            top: 6px;
            margin: 0 10px 0 8px;
            &_active {
              background-color: #ebd53e;
              height: 9.6px;
            }
          }
        }
        @include pc-breakpoint() {
          flex-flow: column;
          width: 100%;
        }
      }
      &_count {
        width: 50%;
        padding: 18px 0;
        display: flex;
        justify-content: flex-start;
        margin-right: 10px;
        &_icon {
          margin: 7px 10px 0 20px;
        }
        &_detail {
          dt {
            font-size: 14px;
            font-weight: 700;
          }
          dd {
            font-size: 20px;
            font-weight: 700;
            line-height: 1;
            b {
              font-size: 26px;
            }
          }
        }
        @include pc-breakpoint() {
          width: 100%;
          margin-bottom: 10px !important;
        }
      }
      &_count:last-child {
        margin-right: 0;
      }
      &_rate {
        width: calc(100% / 3);
        padding: 18px 0 18px 14px;
        display: flex;
        justify-content: flex-start;
        margin-right: 10px;
        &_detail {
          &_txtgray {
            color: #7a7a7a;
            font-size: 14px;
          }
          dt {
            font-size: 16px;
            font-weight: 700;
          }
          dd {
            font-size: 20px;
            font-weight: 700;
            line-height: 1.2;
            b {
              font-size: 35px;
            }
          }
        }
        @include pc-breakpoint() {
          width: 100%;
          margin-bottom: 10px !important;
        }
      }
      &_rate:last-child {
        margin-right: 0;
      }
      &_voice {
        width: 50%;
        padding: 12px 14px;
        display: flex;
        justify-content: flex-start;
        margin-right: 10px;
        flex-flow: column;
        &_title {
          font-size: 16px;
          font-weight: 700;
          border-bottom: 1px solid #ededed;
          padding-bottom: 5px;
          &_img {
            position: relative;
            top: 5px;
            margin-right: 5px;
          }
        }
        &_ul {
          margin-top: 20px;
          &_li {
            list-style: none;
            margin-bottom: 10px;
            height: 25px;
          }
          &_rank {
            font-size: 14px;
            font-weight: 700;
            width: 15px;
            display: inline-block;
          }
          &_content {
            font-weight: 400;
            border: 1px solid #d8d8d8;
            padding: 5px;
            color: #4a4a4a;
            margin-left: 2px;
          }
        }
        @include pc-breakpoint() {
          width: 100%;
          margin-bottom: 10px !important;
        }
      }
      &_voice:last-child {
        margin-right: 0;
      }
    }
  }
}
.u- {
  &trimLeft {
    &-half {
      margin-left: -0.5em;
    }
  }
}

.c-dashboardCard__product {
  flex-wrap: wrap;
}

.c-dashboardCard__productCount {
  flex: 0 0 100%;
}

.c-dashboardCard__recommendedSettings {
  font-size: 16px;

  // SPだとツールチップがはみ出るので、位置を調整する
  // app/styles/farmer/modules/tooltips.scss で定義されている left を上書きしている
  .tooltip-hint-message {
    @include max-breakpoint($sp-width) {
      left: -150px;
    }
  }
}

.c-dashboardCard__recommendedSettingsTitle {
  font-weight: bold;
}

.c-dashboard__contractFarmingOrderMessage {
  font-size: 14px;
  font-weight: bold;
  padding: 8px 14px 0 14px;
}
