.delete-btn {
  position: absolute;
  right: 2px;
  top: 2px;
}

.copy-btn-wrap {
  display: flex;
  align-items: center;
  padding: 2px 0;
  button {
    margin-right: 10px;
  }
}

.button {
  &.is-facebook {
    border: 1px solid #3b579d;
    background-color: #3b579d;
    color: $white;
    .icon {
      //margin-right: 0.5rem;
    }
  }
  &.is-line {
    border: 1px solid #00c300;
    background-color: #00c300;
    color: $white;
    .icon {
      //margin-right: 0.5rem;
    }
  }

  &.is-accept {
    height: 70px;
  }

  &.is-draft {
    height: 50px;
  }
}

.right-corner-floating-button {
  position: fixed;
  z-index: 1;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  border-radius: 50px;
  text-align: center;

  @media screen and (max-width: 1023px) {
    width: 50px;
    height: 50px;
    bottom: 70px;
    right: 10px;
  }

  i {
    margin-top: 22px;
    @media screen and (max-width: 1023px) {
      margin-top: 18px;
    }
  }
}
