.p-recipe {
  $pc-width: 1087;
  $sp-width: 768;

  @mixin min-breakpoint($point) {
    @media screen and (min-width: ($point + 1) + "px") {
      @content;
    }
  }
  @mixin pc-breakpoint() {
    @media screen and (max-width: $pc-width + "px") {
      @content;
    }
  }

  .p-recipe_title {
    font-size: 22px;
    margin: 18px;
    @include min-breakpoint($pc-width) {
      margin: 18px 0;
    }
    @include min-breakpoint($sp-width) {
      margin: 0 0 18px;
    }
  }

  .p-step-description-block {
    flex-direction: column;
    width: 100%;
  }

  .p-step-description {
    width: 100%;
  }

  .p-step-image {
    width: 80px;
  }

  .p-recipe-image {
    max-width: 500px;

    img {
      width: 100%;
    }
  }

  .p-image-delete {
    position: relative;
  }

  .p-image-delete-button {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  .p-recipe-caution-description {
    background: #fdfbf8;
    border: 1px solid #f1eee5;
    border-radius: 4px;
    padding: 10px 10px 10px 30px;
    list-style: circle;
  }

  .p-recipe-arrow {
    cursor: pointer;
  }
}
