@use "./products/salesIncreaseItems";
@use "./products/featuredImage";

.product-image-view {
  position: relative;
  .delete {
    @extend .delete-btn;
  }
}

.item-tags {
  width: 100%;
}

.product-items {
  min-height: 8vh;
  .product-item {
    .product-item-header {
      padding: 0.5rem;
      &:hover {
        background: $light;
      }
      a {
        color: $black;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
      }
    }

    .product-item-list {
      li {
        display: flex;
        &:hover {
          background: $light;
        }
        label {
          flex-grow: 1;
        }
      }
    }
  }
}

.dropdown-button {
  align-items: center;
  display: flex;
  margin-right: 1rem;
  margin-left: auto;
  &:focus {
    outline: none;
  }
  .hidden-input {
    position: absolute;
    top: -9999px;
  }
}

.products {
  .head-actions-container {
    // ...
  }

  .mobile-full-button {
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .head-action-column {
    padding: 6px 6px;

    @media screen and (max-width: 768px) {
      padding: 6px 12px;
    }
  }

  .blank-records {
    text-align: center;
    padding: 60px 20px;
    font-weight: bold;
    width: 100%;
    background-color: white;
  }
}

.item.sp-product-container {
  font-size: 14px;
  padding: 1em;
  padding-right: 0;
}

.status-header {
  padding: 10px 0;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  line-height: 1rem;
  text-align: center;
  font-weight: 700;
}

.media-tags-group {
  margin-bottom: 1rem;
  overflow: hidden;

  figure {
    float: left;
    margin-right: 0.5em;
  }
}

.badge_prohibition {
  padding: 6px 8px 6px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  background-color: #b01f25;
  color: white;
}

.badge_warning {
  padding: 6px 8px 6px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  background-color: #f5e524;
  color: #363636;
}

.sensitive-word {
  margin: 0 8px;
  font-weight: 700;
  color: #363636;
}

.word-explanation {
  margin-top: 8px;
}

.explanations-body {
  max-height: 50vh; // TODO: 仮
}

.forceful-button {
  background-color: #b01f25;
  color: white;

  &:hover {
    background-color: #b01f25;
    color: white;
    opacity: 0.8; // TODO: 仮
  }
}

.itemSuggestions {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 16px 12px 12px 12px;
}

.new-product-instruction {
  padding: 0 1.5rem;
}

/* stylelint-disable selector-class-pattern */
.product-edit-header-dropdown-menu {
  min-width: 15rem;
}
/* stylelint-enable selector-class-pattern */
