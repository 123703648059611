.farmer_inquiry {
  font-size: 13px;
  color: #222222;

  &.tabechoku_admin_inquiry {
    background-color: #fff;
    width: 680px;
    padding: 15px 30px;
    margin: 0 auto;

    .image-gallery .delete {
      text-indent: -999px;
    }

    * {
      font-size: 15px;
    }

    .is-title {
      font-weight: bold;
      background: #dbdbdb;
      border-left: 3px solid;
      font-size: 16px;
      padding: 0.5rem;
    }

    .control-button span {
      position: relative;
      display: inline-block;
      padding-left: 20px;

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        border: 0px;
        border-top: solid 3px #363636;
        border-right: solid 3px #363636;
        transform: rotate(-135deg);
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -4px;
      }
    }

    img {
      max-width: 100%;
    }
    figure {
      margin: 0;
    }
  }

  .image-gallery {
    width: 100%;
    padding: 0;
    font-size: 0;
    display: flex;
    flex-flow: row wrap;

    .image {
      flex: auto;
      width: 180px;
      padding: 0 !important;
      border: 1px solid #fff;
      position: relative;

      &:last-child {
        position: relative;
        display: inline-block;
        overflow: hidden;
        vertical-align: middle;
      }
    }
  }

  .farmer-inquiry-category {
    font-size: 14px;
  }

  .farmer_inquiry_attention_txt {
    color: #c30d23;
    font-weight: bold;
    margin-top: 15px;
    text-align: center;

    img {
      vertical-align: middle;
      margin-right: 5px;
      margin-bottom: 2px;
    }
  }

  .farmer_inquiry_closed_txt {
    background-color: #f3f3f3;
    padding: 13px 10px;
    text-align: center;

    .farmer_inquiry_closed_contact {
      display: inline-block;
      margin-top: 4px;
      border: 2px solid #a8a8a8;
      background-color: #fff;
      padding: 6px 30px;
    }
  }

  .farmer_inquiry_info_list {
    margin-top: 25px;
    margin-bottom: 40px;
  }

  .farmer_inquiry_product_info {
    overflow: hidden;
    margin-bottom: 15px;

    .farmer_inquiry_product_thumb {
      float: left;
      display: block;
      width: 70px;
      height: 70px;
      background-image: url("../../app/assets/images/product_sample.jpg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .farmer_inquiry_detail {
      margin-left: 82px;
      text-align: left;
    }

    .farmer_inquiry_buy_date {
      color: #555;
      font-size: 11px;
    }

    .farmer_inquiry_name {
      margin-top: 10px;
    }

    .farmer_inquiry_farm_name_link {
      color: #a5a5a5;
      font-weight: bold;
    }
  }

  .farmer_inquiry_thread_section_ttl {
    font-size: 17px;
    font-weight: bold;
    padding: 10px;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    text-align: left;
  }

  .farmer_inquiry_block {
    padding: 20px 15px 30px;
    text-align: left;

    &.attention_bgc {
      background: #fff5f5;
    }
  }

  .farmer_inquiry_thread_section_head {
    margin-bottom: 10px;

    .farmer_inquiry_thread_section_head_inner {
      display: flex;
      align-items: center;
    }

    .circle_wrap {
      width: 80%;

      a {
        color: inherit;
      }
    }

    .circle {
      display: flex;
      align-items: center;

      img {
        border-radius: 50%;
        width: auto;
        height: 36px;
      }
    }

    figcaption {
      font-weight: bold;
      margin-left: 10px;
    }
  }

  .farmer_inquiry_thread_del {
    width: 25%;
    min-width: 90px;
    text-align: right;

    .farmer_inquiry_thread_del_btn {
      background: none;
      border: 1px solid #a4a4a4;
      border-radius: 3px;
      padding: 5px 15px;
      color: #7d7d7d;
      cursor: pointer;
    }
  }

  .farmer_inquiry_thread_date {
    font-weight: bold;
    letter-spacing: 0.1px;
    font-size: 12px;
  }

  .farmer_inquiry_thread_main {
    margin-top: 7px;

    p {
      word-break: break-all;
    }
  }

  .farmer_inquiry_block_container {
    .farmer_inquiry_block {
      border-top: 1px solid #dddddd;

      &:first-child {
        border: none;
      }

      &.delete_post {
        background-color: #f1f1f1;
        .farmer_inquiry_thread_date {
          color: #a5a5a5;
        }
        .farmer_inquiry_thread_main {
          font-weight: bold;
          color: #a5a5a5;
          text-align: center;
        }
      }
    }
  }

  .farmer_inquiry_reply_form {
    border: 1px solid #dddddd;
    text-align: left;
  }

  .farmer_inquiry_reply_form_head {
    background-color: #f5f5f5;
  }

  .farmer_inquiry_reply_form_head_ttl {
    padding: 6px 13px;
    font-weight: bold;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 10px;
  }

  .farmer_inquiry_reply_form_body {
    padding: 0 10px 10px;

    .farmer_inquiry_reply_content {
      width: 100%;
      height: 100px;
      padding: 6px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    .btn_layout_01 button {
      background-color: #336699;
    }
  }

  .farmer_inquiry_reply_uploader_block {
    margin-top: 10px;

    .farmer_inquiry_reply_uploader {
      position: static;
      margin-bottom: 30px;
      width: 100%;
      display: flex;
    }

    .image-upload-area {
      padding-top: 70px;
      cursor: pointer;
      background-color: #646464;
      background-image: url("../../app/assets/images/icon-camera.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 90%;
    }
  }

  .farmer_inquiry_reply_uploader {
    .upload-drop-zone {
      position: relative;
      width: 70px;
      flex-shrink: 0;
    }

    input[type="file"] {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: block;
      opacity: 0;
      cursor: pointer;
    }
  }

  .farmer_inquiry_reply_uploader_txt {
    margin-left: 10px;
    display: flex;
    align-items: center;
    color: #757575;
  }

  @media screen and (min-width: 768px) {
    &.account .container {
      padding: 30px;
    }

    .farmer_inquiry_block {
      padding: 20px 25px 30px;
    }

    .farmer_inquiry_thread_main {
      margin-top: 13px;
    }

    .farmer_inquiry_closed_txt {
      padding: 20px 10px;
    }
  }
}

//生産者問い合わせ（生産者管理画面側のみの部分）
.order_and_farmer_inquiry_is_page_ttl {
  text-align: center;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  padding: 10px;
  margin-bottom: 7px;
}

.tag:not(body).is-reply-required {
  color: #fff;
  background-color: #ff003d;
}
.tag:not(body).is-await {
  color: #fff;
  background-color: #43a047;
}
.tag:not(body).is-resolved {
  color: #fff;
  background-color: #7d7d7d;
}

.media-content .farmer_inquiry_media_item_txt {
  font-weight: normal;
}
.farmer_inquiry_media_item_txt_update {
  text-align: right;
  font-size: 12px;
  margin-top: 10px;

  .farmer_inquiry_media_item_txt_update_time {
    font-weight: bold;
  }
}

.farmer_inquiry_media_item_txt {
  p {
    position: relative;
    max-height: calc(15 * 1.6 * 3 * 1px);
    font-size: 15px;
    line-height: 1.6;
    word-break: break-all;
    overflow: hidden;
  }
  p::before,
  p::after {
    position: absolute;
    background: #fff;
  }
  p::before {
    content: "...";
    top: calc(15 * 1.6 * (3 - 1) * 1px);
    right: 0;
    width: 1em;
  }
  p::after {
    content: "";
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .farmer_inquiry_media_item_txt {
    p {
      max-height: calc(15 * 1.6 * 2 * 1px);
    }
    p::before {
      top: calc(15 * 1.6 * (2 - 1) * 1px);
    }
  }
}

.new-inquiry-wrap {
  margin-bottom: 20px;
  text-align: center;
  @media screen and (min-width: 769px) {
    display: flex;
  }
}

.new-inquiry-content {
  margin: 10px;
  @media screen and (min-width: 769px) {
    width: 100%;
    flex: 1;
    margin: 0 20px;
  }
}

.new-inquiry-btn {
  border: 2px solid #336699;
  border-radius: 3px;
  display: inline-block;
  font-weight: bold;
  padding: 5px 20px;
  @media screen and (min-width: 769px) {
    max-width: 100%;
  }

  &:hover {
    opacity: 0.8;
    color: #336699;
  }
}

.new-inquiry-order-cancel-btn {
  border: 2px solid #c30d23;
  border-radius: 3px;
  display: inline-block;
  font-weight: bold;
  padding: 5px 20px;
  @media screen and (min-width: 769px) {
    max-width: 100%;
  }

  &:hover {
    opacity: 0.8;
    color: #c30d23;
  }
}

.farmer_inquiry.farmer_admin {
  font-size: 16px;
  padding: 0 10px;

  .farmer_inquiry_closed_txt {
    font-size: 15px;
    text-align: center;
    margin: 10px -10px;
  }
}
.farmer-inquiry-resolve {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 11px;
  margin-right: -13px;
}
.farmer-inquiry-resolve-help {
  width: 32px;
  margin-left: 10px;
}
.farmer-inquiry-resolve-btn {
  background: none;
  border: 2px solid #c30d23;
  border-radius: 3px;
  color: #c30d23;
  background-color: #fff;
  font-weight: bold;
  padding: 5px 45px;
  cursor: pointer;
}
.farmer-inquiry-resolve-btn--reopen {
  border-color: #a8a8a8;
  color: #a8a8a8;
}
.new-farmer-inquiry-numlink {
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
    color: #336699;
  }
}
.new-farmer-inquiry {
  margin-bottom: 30px;
}
.new-farmer-inquiry-form-block {
  .new-farmer-inquiry-category-label {
    font-weight: bold;
  }
  .farmer_inquiry_reply_uploader {
    font-size: 14px;
    margin: -10px 0;
  }
  .btn_layout_01 {
    button {
      cursor: pointer;
      margin: 20px auto 20px;
      text-align: center;
      background-color: #336699;
      display: block;
      width: 100%;
      padding: 13px;
      color: #fff;
      border-radius: 4px;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    .main_txt {
      font-size: 14px;
      font-weight: bold;
    }
  }
}
.farmer-inquiry-category {
  position: relative;
  width: 100%;
  padding: 8px 20px 11px 10px;
  margin-bottom: 20px;
  font-size: 12px;
  text-align: left;
  border: 1px solid #ccc;
  background-image: url("../../app/assets/images/icon-arrow-down.png");
  background-repeat: no-repeat;
  background-position: 95%;
  background-size: 8px auto;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.farmer-inquiry-content {
  width: 100%;
  min-height: 200px;
  font-size: 14px;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.new-farmer-inquiry-form-required {
  color: #c30d23;
  margin-left: 10px;
  font-size: 14px;
}
.new-farmer-inquiry-note {
  font-size: 13px;
  text-indent: -0.6em;
  padding-left: 0.7em;
  line-height: 1.3;
  margin: 5px 0 7px;
}
.new-farmer-inquiry-data {
  border-bottom: 1px solid #dddddd;
}
.farmer_inquiry_attention_txt.is-farmer-inquiry {
  border-top: 1px solid #dddddd;
  padding: 10px;
  font-size: 14px;
  margin: 0 -10px;
  line-height: 1.3;
}
.farmer-inquiry-status {
  margin-top: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  background-color: #fff;

  .farmer-inquiry-status-btn {
    display: block;
    text-align: center;
    padding: 10px;
    color: inherit;
    font-weight: bold;

    &.is-active {
      .farmer-inquiry-status-btn-arrow:after {
        top: 40%;
        transform: rotate(-45deg);
      }
    }
    &.is-active + .check-inquiry-status-form-inner {
      padding: 0 15px 20px;
      height: auto;
      overflow: inherit;
    }
  }
  .farmer-inquiry-status-btn-arrow {
    position: relative;
    &:after {
      position: absolute;
      right: -23px;
      display: block;
      content: "";
      width: 10px;
      height: 10px;
      border-top: 3px solid #369;
      border-right: 3px solid #369;
      transition: 0.3s ease-in-out;
      top: 7%;
      transform: rotate(135deg);
    }
  }
}
.farmer-inquiry-terms-dialog {
  /* 規約同意モーダル */
  .modal-terms {
    font-size: 14px;
    text-align: center;
  }
  .btn-modal-terms-close {
    border: none;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2f2f2;
    border-radius: 50%;
    position: relative;
    margin-top: -10px;
    margin-right: -5px;

    span {
      position: absolute;
      display: block;
      width: 14px;
      height: 2px;
      left: calc(50% - 7px);
      background: #403a39;
      border-radius: 4px;
    }
    span:nth-child(1) {
      transform: rotate(-45deg);
      top: calc(50% - 1px);
    }
    span:nth-child(2) {
      transform: rotate(45deg);
      top: calc(50% - 1px);
    }
  }

  a.link-modal-terms {
    color: #336699;
  }

  .btn-modal-terms-accept {
    width: 250px;
    margin: 20px auto 20px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    background-color: #336699;
    color: #fff;
  }

  .btn-modal-terms-close-wrap {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;
  }

  .label-modal-terms-accept {
    margin-top: 10px;
    display: inline-block;
    position: relative;
    padding-left: 24px;
    font-size: 14px;
    cursor: pointer;

    &:before {
      position: absolute;
      content: "";
      top: 50%;
      left: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ccc;
      background: #fff;
      transform: translateY(-50%);
    }
    &:after {
      display: none;
      position: absolute;
      content: "";
      top: 50%;
      left: 3px;
      width: 16px;
      height: 8px;
      margin-top: -11px;
      border-left: 3px solid #cab270;
      border-bottom: 3px solid #cab270;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    .txt {
      display: inline-block;
    }
  }
  .checkbox-modal-terms {
    border: none;
    appearance: none;
  }
  .checkbox-modal-terms:checked + .label-modal-terms-accept:after {
    display: block;
    box-sizing: content-box;
  }
  button:disabled {
    border-color: #dbdbdb;
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.check-inquiry-status-field-wrap + .check-inquiry-status-ttl {
  margin-top: 20px;
}
.check-inquiry-status-form-inner {
  padding: 0;
  height: 0;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}
.check-inquiry-status-ttl {
  margin: 10px 0;
  padding-left: 10px;
  border-left: 3px solid;
}
.check-inquiry-status-field-items {
  display: flex;
  flex-wrap: wrap;
  margin-top: -5px;
}
.check-inquiry-status-field-item {
  width: 33.33%;
}
