// これは古いcheckbox-list
.checkbox-list {
  display: flex;
  &:hover {
    background: $light;
  }
  label {
    flex-grow: 1;
    display: block;

    &.checkbox {
      display: block !important;
      text-align: left;
    }
  }
}

.inline-checkbox-list {
  display: inline;

  label {
    display: block;
  }

  .inline-checkbox {
    display: inline-flex;
    align-items: center;
    margin: 4px 0;
  }
}
