.guideline {
  .title {
    margin-top: 20px;
  }

  .guideline-items {
    margin-top: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid #f5f5f5;
    padding: 0 5px;
  }

  .mt10 {
    margin-top: 10px;
  }

  .bold-txt {
    font-weight: bold;
    font-style: normal;
  }

  ul.txt-indent {
    li {
      padding-left: 1em;
      text-indent: -1em;
    }
  }

  dl.txt-indent {
    dt {
      padding-left: 1em;
      text-indent: -1em;
    }

    dd {
      padding-left: 1em;
    }
  }

  p.txt-indent {
    padding-left: 0.7em;
    text-indent: -0.6em;
  }

  .guideline-item-dt {
    color: $blue;
    font-weight: bold;
    padding: 10px 5px;
    padding-right: 1.3em;
    position: relative;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:after {
      font-family: "Font Awesome 5 Free"; // stylelint-disable-line font-family-no-missing-generic-family-keyword
      font-weight: 900;
      content: "\f078";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-48%);
    }
  }

  .guideline-item-dd + .guideline-item-dt {
    border-top: 1px solid $grey-lighter;
  }

  .guideline-item-dd {
    border-top: 1px solid #f5f5f5;
    padding: 15px 5px 35px;
    display: none;

    p + p {
      margin-top: 10px;
    }
  }

  .guideline-item-dt.is-open {
    &:after {
      transform: rotateZ(180deg);
      top: 33%;
    }
  }

  .guideline-item-dt.is-open + .guideline-item-dd {
    display: block;
  }

  .guideline-point {
    margin: 30px 0 15px 0;
    border: 1px solid $orange;
    padding: 20px 10px 10px;
  }

  .guideline-point-ttl {
    clear: both;
    float: left;
    background: $orange;
    color: #fff;
    font-weight: bold;
    padding: 0px 7px 0px 9px;
    margin-top: -33px;
    margin-left: -11px;
    border-radius: 4px;
  }

  .guideline-last {
    padding: 40px 10px;
    text-align: center;
  }

  @media screen and (min-width: 600px) {
    .title {
      margin-top: 0;
    }

    .guideline-item-dt {
      padding: 15px 15px 15px 10px;

      &:after {
        right: 7px;
      }
    }

    .guideline-item-dd {
      padding: 15px 10px 35px;
    }
  }
}
