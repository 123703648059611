.salesIncreaseItems {
  margin: 12px 24px 0;
  border-top: 1px solid #f5f5f5;
  padding: 12px 0;

  .salesIncreaseItems__title {
    font-size: 14px;
    font-weight: 600;
  }

  .salesIncreaseItems__item {
    display: flex;
    align-items: center;
    font-size: 14px;

    svg {
      margin-right: 8px;
    }

    &:nth-child(n + 2) {
      margin-top: 4px;
    }

    .salesIncreaseItems__link {
      color: #363636 {

      }
    }
  }

  .salesIncreaseItems__progress {
    align-items: center;
    display: flex;
    color: #209cee;
    font-size: 12px;
    font-weight: 600;
    margin: 8px 0px;

    .salesIncreaseItems__progressBar {
      width: 50%;
      margin-right: 10px;
    }
  }
}
