.p-products__itemNeedNotification {
  padding: 16px;
  background: #fff;
  border-radius: 8px;
  border: solid 1px #dbdbdb;
}

.p-products__itemNeedNotificationBlock {
  max-height: 0;
  transition: max-height 0.4s ease-out;
  overflow: hidden;
  &.is-open {
    max-height: 100vh;
  }
}

.p-products__itemNeedNotificationItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  line-height: 18.4px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.p-products__itemNeedNotificationItemMessage {
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.p-products__itemNeedNotificationItemIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 8px;
}

.p-products__itemNeedNotificationItemLinkBlock {
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    justify-content: flex-end;
  }
}
.p-products__itemNeedNotificationItemLink {
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  color: $blue;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.p-products__itemNeedNotificationCollapseWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-products__itemNeedNotificationCollapse {
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  color: #7a7a7a;
  gap: 4px;
}

.p-products__itemNeedNotificationCollapseText {
  margin-right: 4px;
}

.p-products__filteringItemTag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.p-products__itemNeedNotificationAnswerModal {
  max-width: 95vw;
}

.p-products__itemNeedNotificationAnswerModalHeader {
  font-size: 20px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.p-products__itemNeedNotificationAnswerModalDescription {
  padding-bottom: 12px;
  font-size: 16px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.p-products__itemNeedNotificationAnswerBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.p-products__itemNeedNotificationAnswerInput {
  margin-right: 8px;
}

.p-products__itemNeedNotificationAnswerCommentBlock {
  padding: 8px 0;
}

.p-products__itemNeedNotificationAnswerModalFooter {
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 8px;

    .p-products__itemNeedNotificationAnswerModalFooterButton {
      width: 100%;
      // .modal-card-foot .button:not(:last-child) で margin-right が設定されるため 0 にする
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}

.p-products__itemNeedNotificationAnswerModalFooterButtonRegister {
  @media screen and (max-width: 768px) {
    order: -1;
  }
}

.p-products__itemNeedNotificationAnswerCompleteModal {
  max-width: 95vw;
}

.p-products__itemNeedNotificationAnswerCompleteModalContent {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-products__itemNeedNotificationAnswerCompleteHeadingWrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.p-products__itemNeedNotificationAnswerCompleteHeadingContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-products__itemNeedNotificationAnswerCompleteHeadingImage {
  width: 72px;
  height: 75px;
  margin-bottom: 12px;
}

.p-products__itemNeedNotificationAnswerCompleteHeadingText {
  font-size: 20px;
  font-weight: 700;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.p-products__itemNeedNotificationAnswerCompleteMessage {
  font-size: 16px;
  display: block;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.p-products__itemNeedNotificationAnswerCompleteModalFooterButton {
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
