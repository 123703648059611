.c-emotionSymbol {
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  padding-right: 16px;
  border-radius: 14px;
  background: #f5f1eb;
  color: #9f8745;
  font-size: 12px;
  font-weight: 600;
}
