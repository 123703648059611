.farmer-list-table {
  font-size: 14px;
  word-break: break-all;

  th,
  td {
    vertical-align: middle;
    &.more-padding {
      padding: 0.25em 2em;
    }
  }

  .farmer-list-table-row {
    cursor: pointer;
  }

  .table-middle-checkbox {
    position: relative;
    top: 10px;
  }

  .has-following-tr {
    td {
      border-bottom: 1px solid white;
    }
  }

  .has-background-red-light {
    background-color: #feecf0 !important;
  }
  .has-background-green-light {
    background-color: #effaf3 !important;
  }

  .tr-tiny {
    min-width: 50px;
  }
  .tr-small {
    min-width: 80px;
    height: 50px;
  }
  .tr-medium {
    min-width: 160px;
  }
  .tr-large {
    min-width: 240px;
  }
  .tr-xlarge {
    min-width: 360px;
  }
  .td-large {
    min-width: 240px;
  }
  .td-middle {
    margin: auto;
  }
  .tr-large-two-line {
    min-width: 240px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .two-line {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 6.5px;
    margin-bottom: 6.5px;
  }

  .tr-top-double-border {
    border-top: 4px double $blue;
  }

  &.multi-line-table {
    thead {
      &.is-narrow-header {
        th {
          height: 30px;
        }
      }
      tr {
        background-color: #f5f5f5;
      }
      tr:last-child {
        th {
          border-bottom: 2px solid #dbdbdb;
        }
      }
      tr:not(:last-child) {
        th[rowspan] {
          border-bottom: 2px solid #dbdbdb;
        }
        th {
          border-bottom: 1px solid #dbdbdb;
        }
        th[rowspan]:last-child {
          padding: 0.25em 2em;
        }
      }
    }

    tbody {
      tr {
        th {
          border-bottom: 2px solid #dbdbdb;
        }

        td {
          border-bottom: 1px solid #efefef;
          height: 50px;
        }
      }
      tr:nth-child(odd) {
        td[rowspan]:last-child {
          padding: 0.25em 2em;
        }
      }
      .line-tr {
        border-bottom: 2px solid #dbdbdb;
      }
    }

    .line-info {
      border-top: 3px solid white;
      border-bottom: 2px solid #dbdbdb;
    }
    .line-success {
      color: white;
      background-color: #22b24c;
    }

    &.is-striped {
      tbody {
        tr:nth-child(even) {
          background-color: $table-background-color;
        }
        tr:nth-child(4n + 3) {
          background-color: $table-striped-row-even-background-color;
        }
        tr:nth-child(4n + 4) {
          background-color: $table-striped-row-even-background-color;
        }
      }
    }
  }
}

.farmer-list-table-grad-container {
  position: relative;

  .grad-item {
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: calc(100% - 24px);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.7) 50%, #fff 100%);
    content: "";
  }
}

.fixedMultiHeader__container {
  overflow: auto;
  width: 100%;
  height: auto;
  max-height: 70vh;
  border-style: solid;
  border-color: #dbdbdb;
  border-width: 1px 1px 0 1px;
}

.fixedMultiHeader__table {
  border-collapse: separate;

  &.is-bordered td,
  &.is-bordered th {
    border-width: 0 1px 1px 0;
  }

  .product__rowHeader {
    left: 0px;
  }

  .monthDay__rowHeader {
    left: 160px;
  }

  .product__rowHeader,
  .monthDay__rowHeader {
    position: sticky;
    position: -webkit-sticky;
    background: white;
  }

  .fixed__columnHeader {
    top: 0;
    z-index: 1;
    position: sticky;
    position: -webkit-sticky;
    background-color: white;

    .product__columnHeader {
      left: 0px;
    }

    .month__columnHeader,
    .day__columnHeader {
      left: 160px;
    }

    .product__columnHeader,
    .month__columnHeader,
    .day__columnHeader {
      position: sticky;
      position: -webkit-sticky;
      background-color: white;
    }
  }
}
