/* loading shield */

.loading-shield {
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: 0.3s opacity;
  z-index: 9999;

  .loading-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100px;
    width: 150px;
    margin: auto;
    padding: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    color: #333;
    background-color: #eee;
    text-align: center;
  }

  .loading-inner > * {
    vertical-align: middle;
  }

  .loading-text {
    display: inline-block;
    margin-left: 0.4em;
    font-weight: 400;
    font-size: 13px;
    line-height: 25px;
  }
}

.loading-shield-ns {
  opacity: 0;
  width: 0;
  height: 0;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.loading-shield-fade {
  opacity: 1;
}

@-webkit-keyframes page-loading {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes page-loading {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-ms-keyframes page-loading {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}

@-o-keyframes page-loading {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}

@keyframes page-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes page-loading-fadeout {
  0% {
    opacity: 1;
    height: 100%;
  }
  99% {
    height: 100%;
  }
}

@-moz-keyframes page-loading-fadeout {
  0% {
    opacity: 1;
    height: 100%;
  }
  99% {
    height: 100%;
  }
}

@-ms-keyframes page-loading-fadeout {
  0% {
    opacity: 1;
    height: 100%;
  }
  99% {
    height: 100%;
  }
}

@-o-keyframes page-loading-fadeout {
  0% {
    opacity: 1;
    height: 100%;
  }
  99% {
    height: 100%;
  }
}

@keyframes page-loading-fadeout {
  0% {
    opacity: 1;
    height: 100%;
  }
  99% {
    height: 100%;
  }
}
