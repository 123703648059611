.align-right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.no-z-index {
  z-index: 0 !important;
}
