.flexbox {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.no-display {
  display: none;
}

body {
  background-color: $white-bis;
}

// header
header {
  height: 52px; // for IE
  .navbar-burger {
    margin-left: 0;
    margin-right: auto;
  }

  .dropdown-menu {
    width: 18rem;

    .dropdown-item {
      .media-content {
        overflow: hidden;
      }
    }
  }
  .notification-list {
    max-height: 25rem;
    overflow: scroll;
  }
}

/* stylelint-disable selector-class-pattern -- bulma update に関する修正のため、今は対応しない */

// main
main {
  margin-top: 0;
  .fm-side {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    &.is-3 {
      max-width: 400px;
    }
  }
  .fm-lead {
    padding: 1.5rem;
    &.is-9 {
      @media screen and (min-width: 1654px) {
        width: 100%;
        max-width: calc(100% - 400px);
      }
    }
  }
}
