.harvest-calendar {
  .menu {
    padding: 0.25rem;
    display: -ms-flexbox !important;
    display: flex !important;
    justify-content: space-between;

    .menu-left,
    .menu-right {
      display: -ms-flexbox !important;
      display: flex !important;
      align-items: flex-end;
    }
  }

  .pager {
    display: -ms-flexbox !important;
    display: flex !important;
    .control {
      width: 40px;
    }
  }

  .header {
    text-align: center;
    width: 100%;
    display: -ms-flexbox !important;
    display: flex !important;
    margin-top: 0.75rem;

    .item-title {
      border-top: #e0e0e0 1px solid;
      border-right: #e0e0e0 1px solid;
      border-bottom: #e0e0e0 1px solid;
      width: 30%;
    }
    .col {
      border-top: #e0e0e0 1px solid;
      border-right: #e0e0e0 1px solid;
      border-bottom: #e0e0e0 1px solid;
    }
    .cols {
      border-top: #e0e0e0 1px solid;
      border-right: #e0e0e0 1px solid;
      border-bottom: #e0e0e0 1px solid;
    }
  }

  .schedule-date-active {
    position: absolute;
    top: 25%;
    width: 100%;
    height: 50%;
  }

  .week {
    .header {
      .col {
        width: calc(70% / 7);
      }
    }

    .items {
      display: -ms-flexbox !important;
      display: flex !important;
      width: 100%;
      .titles {
        width: 30%;
        .item-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          border-right: #e0e0e0 1px solid;
          border-bottom: #e0e0e0 1px solid;
          padding: 0.5rem;
        }
      }

      .schedules {
        width: 70%;
        display: -ms-flexbox !important;
        display: flex !important;
        flex-wrap: wrap;
        .schedule-date {
          flex-basis: calc(100% / 7);
          border-right: #e0e0e0 1px solid;
          border-bottom: #e0e0e0 1px solid;
          width: calc(100% / 7);
          position: relative;
        }
      }
    }
  }

  .month {
    .header {
      .col {
        width: calc(70% / 5);
      }
    }

    .items {
      display: -ms-flexbox !important;
      display: flex !important;
      width: 100%;
      .titles {
        width: 30%;
        .item-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          border-right: #e0e0e0 1px solid;
          border-bottom: #e0e0e0 1px solid;
          padding: 0.5rem;
        }
      }

      .schedules {
        width: 70%;
        display: -ms-flexbox !important;
        display: flex !important;
        flex-wrap: wrap;
        .schedule-date {
          flex-basis: calc(100% / 5);
          border-right: #e0e0e0 1px solid;
          border-bottom: #e0e0e0 1px solid;
          width: calc(100% / 5);
          position: relative;
        }
      }
    }
  }

  .year {
    &.copy {
      .header {
        width: 100%;
        .item-check {
          border-top: #e0e0e0 1px solid;
          border-right: #e0e0e0 1px solid;
          border-bottom: #e0e0e0 1px solid;
          border-left: #e0e0e0 1px solid;
          width: 40px;
        }

        .item-title {
          width: 150px;
        }
        .col {
          width: calc((100% - 190px) / 12);
        }
      }

      .items {
        .titles {
          width: initial;
          .checkbox {
            width: 40px;
            border-right: #e0e0e0 1px solid;
            border-left: #e0e0e0 1px solid;
            border-bottom: #e0e0e0 1px solid;
          }

          .item-title {
            width: 150px;
          }
        }

        .schedules {
          width: 100%;
          .schedule-date {
            flex-basis: calc(100% / 12);
            width: calc(100% / 12);
          }
        }
      }
    }

    .header {
      margin-top: 0;
      &:first-child {
        margin-top: 0.75rem;
      }
      .cols {
        width: calc(70% / 12);
      }
      .col {
        width: calc(70% / (12 * 3));
      }
    }

    .items {
      display: -ms-flexbox !important;
      display: flex !important;
      width: 100%;
      .titles {
        width: 30%;
        .item-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          border-right: #e0e0e0 1px solid;
          border-bottom: #e0e0e0 1px solid;
          padding: 0.5rem;
        }
      }

      .schedules {
        width: 70%;
        display: -ms-flexbox !important;
        display: flex !important;
        flex-wrap: wrap;
        .schedule-date {
          flex-basis: calc(100% / (12 * 3));
          border-right: #e0e0e0 1px solid;
          border-bottom: #e0e0e0 1px solid;
          width: calc(100% / (12 * 3));
          position: relative;
        }
      }
    }
  }
}
