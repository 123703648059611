/* Tooltip Component ---------------------- */
// 商品代説明用の吹き出しデザイン
.tooltip-hint {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  width: 40px; // ホバーできる領域を拡げる

  .tooltip-hint-message {
    text-align: left;
    visibility: hidden;
    width: 30em;
    max-width: 70vw;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    left: -40px; // アイコンの真下になってしまうので、少し左に位置をずらす
    z-index: 10;
    opacity: 0;
    transition: opacity 0.5s;
    @media screen and (max-width: 400px) {
      width: 80vw;
    }
  }
  &:hover .tooltip-hint-message {
    opacity: 1;
    visibility: visible;
  }
}
/* ---------------------- Tooltip Component */
