/* Review */
.rank {
  .rank-list {
    @extend .flexbox;
    justify-content: left;
    align-items: center;
    input {
      display: none;
    }
    .score {
      float: none;
      width: 24px;
      height: 24px;
      &.on {
        figure {
          background-image: url(" ../../app/assets/images/icon-star-on.svg");
        }
      }

      figure {
        display: block;
        width: 100%;
        height: 100%;
        background: {
          image: url(" ../../app/assets/images/icon-star-off.svg");
          repeat: no-repeat;
          position: center center;
          size: cover;
        }
      }
    }
  }
}
