.product-item-history-dropdown {
  .dropdown-menu {
    min-width: 16rem;
  }

  a.dropdown-item,
  button.dropdown-item {
    padding: 0.375rem 1rem;
    white-space: normal;
  }
}

a.dropdown-item {
  padding-right: 1rem;
  @media screen and (max-width: 768px) {
    width: 100vw;
    white-space: unset;
  }
}

.order-search-form {
  margin-top: 8px;
  display: flex;

  @media screen and (min-width: 600px) {
    .left {
      padding-right: 4px;
      flex-grow: 1;
      flex-basis: 65%;
    }
  }

  @media screen and (max-width: 768px) {
    .clear-link {
      line-height: 20px;
    }
  }

  .two-column {
    padding-top: 4px;
    flex-basis: 50%;
  }
}

.check-status-form {
  padding: 4px 0 4px 4px;
  display: flex;

  .field {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

// 一覧データ
.farmer-record-list {
  .title {
    text-align: center;
    border-bottom: 1px solid rgba(219, 219, 219, 0.5);
    padding: 10px;
    margin-bottom: 7px;
    font-size: 16px;
  }
}

.farmer-orders {
  .head-actions-container {
    // ...
  }

  .mobile-full-button {
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .head-action-column {
    padding: 6px 6px;

    @media screen and (max-width: 768px) {
      padding: 6px 12px;
    }
  }

  .blank-records {
    text-align: center;
    padding: 60px 20px;
    font-weight: bold;
    width: 100%;
    background-color: white;
  }

  .is-collapsible {
    height: auto;
  }
}

// 注文タスク管理機能(簡易検索)
.task-name .title {
  font-size: 0.9rem;
}

.order-tasks_button {
  flex-direction: column;
  justify-content: center;
  height: 70px;
  cursor: pointer;
  &:focus:not(:active) {
    box-shadow: none;
  }
}
.task-name {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.task-name .title {
  font-size: 0.9rem;
}
.order-column {
  padding: 0 0.375rem 0.75rem;
}

@include mobile {
  .is-pc-only {
    display: none;
  }
  .order-tasks_button {
    flex-flow: row;
    justify-content: space-between;
    height: 50px;
  }
  #order-tasks .column {
    padding-top: 0;
    padding-bottom: 10px;
  }
  #order-tasks .column:nth-last-child(1) {
    padding-bottom: 20px;
  }
  .task-name .title {
    font-size: 1rem;
  }
}

.card-header {
  width: 100%;
  box-shadow: none;
}

.card-header-title {
  justify-content: space-between;
  color: #7a7a7a;
  padding: 0.75rem;
}

.card-content {
  padding: 12px;
}

.has-text-specify,
.has-text-unspecified {
  font-weight: bold;
}

.has-text-specify {
  color: #f57a00;
}

.has-text-unspecified {
  color: #2b648d;
}

.inactive {
  background-color: #d9d8d8;
  color: #7a7a7a;
  border: 1px solid #bcbcbc;

  .title,
  .subtitle {
    color: #7a7a7a;
  }
}

.order-card {
  margin-bottom: 12px;
  &_body {
    @include desktop {
      height: auto;
    }
  }

  /* stylelint-disable selector-class-pattern -- bulma update に関する修正のため、今は対応しない */
  .card-header-icon {
    padding: 0.75rem;
    > .icon {
      border-radius: 50%;
      color: #ffffff;
      background-color: #3e598d;
      transform: rotate(180deg);
    }
  }
  .is-active {
    > .card-header-icon {
      > .icon {
        transform: rotate(0);
      }
    }
  }
  /* stylelint-enable selector-class-pattern */
}

.order-detail-search,
.order-tasks_button {
  width: 100%;
  &.is-active {
    border: 3px solid #369;
    .title,
    .subtitle {
      color: #369;
    }
  }
}

// お届けする品目のレイアウト
.label-ordered-item {
  width: 100%;
  text-align: center;
}

// お客様メモ
.c-customerInfo {
  margin: 0 1rem 1rem;
  border-top: 1px solid #f0f1f4;
  padding-top: 1rem;
  font-size: 14px;
  &_body {
    position: relative;
    margin-bottom: 0.5rem;
    overflow: hidden;
    &.is-open {
      .c-customerInfo_more {
        display: none;
      }
      .c-customerInfo_content {
        height: auto;
        max-height: none;
      }
    }
  }
  &_more {
    position: absolute;
    right: 0;
    top: 130px;
    z-index: 1;
    color: #336699;
    font-weight: bold;
    background: #fff;
    padding: 0 4px 0 20px;
    line-height: 1;
    cursor: pointer;
  }
  &_content {
    position: relative;
    overflow: hidden;
    max-height: 150px;
    overflow-wrap: break-word;
  }
  &_button {
    color: #336699;
    border: 1px solid #336699;
    border-radius: 3px;
    display: inline-block;
    font-weight: bold;
    padding: 2px 10px;
    &:hover {
      color: #336699;
      opacity: 0.8;
    }
  }
  &_note {
    color: #f57a00;
    margin: 0.5rem auto;
  }
}

// お客様メモモーダル
.c-customerInfoModal {
  &_name {
    display: flex;
    align-items: center;
    height: 48px;
  }
  .modal-card-body {
    padding: 20px 0;
  }
  &_customerInfo {
    padding: 0 20px 10px 20px;
  }
  &_form {
    padding: 10px 20px 0 20px;
    border-top: 1px solid #f0f1f4;
  }
}

.p-orders__changedCollectOn {
  text-decoration: line-through;
}

.p-order__messageNote {
  color: $orange;
  font-size: 0.75rem;
  font-weight: 400;
}
