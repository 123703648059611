.c-loyalUserPost {
  margin: 20px 5px;

  .c-loyalUserPost__content {
    overflow-wrap: break-word;
    font-size: 14px;
  }

  .c-loyalUserPost__footer {
    margin-top: 30px;
    border-top: 1px solid #dbdbdb;
  }

  .c-loyalUserPost__footerItem {
    text-align: left;
    margin-top: 5px;
  }
}
