.c-titleLabel {
  display: flex;
  align-items: center;
}

.c-titleLabel__wrapper {
  padding: 0px 4px;
  font-size: 10px;
  font-weight: 700;
  border-radius: 4px;
}

.c-titleLabel__warning {
  background-color: #ffffff;
  color: #f57a00;
}
