// bulk actions

.bulk-action-table {
  .tr-medium {
    min-width: 100px;
  }

  .tr-large {
    min-width: 200px;
  }

  .tr-xlarge {
    min-width: 300px;
  }

  .tr-xxlarge {
    min-width: 600px;
  }

  .full {
    width: 100%;
  }

  input {
    font-size: 12px;
  }

  select {
    font-size: 12px;
  }

  textarea {
    font-size: 9px;
  }

  .is-changed {
    background-color: #f8fa9b;
  }
}

.bulk-action-modal-table {
  th {
    vertical-align: middle;
  }

  .full {
    width: 100%;
  }

  .tr-medium {
    min-width: 100px;
  }

  .tr-large {
    min-width: 200px;
  }

  .tr-xlarge {
    min-width: 300px;
  }
}
