// Index

.p-promotionMessageIndex {
  .section {
    padding-bottom: 10px;
    @media screen and (max-width: 768px) {
      padding: 20px 16px;
    }
  }
  .section.p-promotionMessageSection {
    @media screen and (max-width: 768px) {
      padding: 0px;
    }
  }
}

.p-promotionMessageTable--pc {
  .p-promotionMessageTable__th {
    min-width: 90px;
  }
  .p-promotionMessageTable__th--deliversAt {
    min-width: 150px;
  }
  .p-promotionMessageTable__th--message {
    min-width: 270px;
  }
  .p-promotionMessageTable__td--message {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

.p-promotionMessageListSp {
  display: flex;
  flex-direction: column;
  gap: 14px;
  background-color: #fafafa;
}

.p-promotionMessageListSp__card {
  border-bottom: 1px solid #f5f5f5;
  box-shadow: 0px 1px 4px 0px #0000001a;
  padding: 26px 16px;
  background-color: #ffffff;
}

.p-promotionMessageSpec__item {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 6px;
}
.p-promotionMessageSpec__label {
  font-size: 14px;
  min-width: 134px;
  padding: 2px 12px 4px 0;
}

.p-promotionMessageSpec__label--fit {
  min-width: fit-content;
}

.p-promotionMessageListSp__messageWrap {
  background-color: hsl(0, 0%, 96%);
  border-radius: 4px;
  position: relative;
  padding: 10px 30px 10px 10px;
  min-height: 3em;
}

.p-promotionMessageListSp__message {
  white-space: pre-line;
}

.p-promotionMessageListSp__message--collapse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.p-promotionMessageListSp__messageBtn {
  border: 0;
  background: transparent;
  position: absolute;
  right: 10px;
  top: 10px;
}

// Form

.p-promotionMessageFrom {
  .field {
    border-bottom: 0;
  }
  .select {
    width: 100%;
    select {
      width: 100%;
    }
  }
  // stylelint-disable tabechoku/selector-type-allowed-list
  .modal-card {
    padding: 0 15px;
  }
  .modal-card-body {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    p {
      font-size: 22px;
      font-weight: 700;
      padding: 12px;
    }
  }
  .modal-card-foot .button {
    @media screen and (max-width: 768px) {
      display: flex;
      width: 100%;
    }
  }
  // stylelint-enable tabechoku/selector-type-allowed-list
}

.p-promotionMessageFromButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 10px;

  .button {
    min-width: 330px;

    @media screen and (max-width: 768px) {
      min-width: 100%;
    }
  }

  // stylelint-disable selector-class-pattern
  .button.is-delete-button {
    color: #f57a00;
    border-color: #f57a00;
  }
  // stylelint-enable selector-class-pattern
}

.p-promotionMessageDeliversAt__wrap {
  width: 100%;
}

.p-promotionMessageSetting__wrap {
  border-bottom: 1px solid #dbdbdb;
}

.p-promotionMessageAlert__wrap {
  border: 1px solid #f57a00;
  color: #f57a00;
  width: fit-content;

  margin-bottom: 12px;
  padding: 5px 12px;
  font-size: 16px;
  font-weight: 700;
  color: #f57a00;

  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
}

.p-promotionMessageAlert__error {
  border-color: #b01f25;
  color: #b01f25;
}

.p-promotionMessageAlert__warning {
  color: #f57a00;
}

.p-promotionMessageCapacity__wrap {
  padding: 16px;
  border-top: 1px solid #dbdbdb;

  &:first-child {
    border-top: none;
  }
}

.p-promotionMessageNote__content {
  font-size: 14px;
}

.p-promotionMessageNote__specialNote {
  background-color: #ffffff;
  color: #363636;
  padding: 12px;
  font-size: 12px;
}

.p-promotionMessageNote__strongText {
  font-weight: 700;
}

.p-promotionMessageNote__stopText {
  font-weight: 700;
  color: #ff2e12;
}

.p-promotionMessageGuideLink__wrap {
  text-align: right;
  padding-bottom: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;

  &::after {
    content: "";
    height: 8px;
    width: 8px;
    transform: rotate(45deg);
    border-top: 1px solid #336699;
    border-right: 1px solid #336699;
  }
}

.p-promotionMessageGuideLink__text {
  padding-right: 8px;
  color: #336699;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}

.p-promotionMessageNote__atMessage {
  color: #ff2e12;
}

.p-promotionMessageNote__atMessageStrongText {
  font-weight: 700;
}

.p-promotionMessage__radioList {
  display: flex;
  gap: 16px;

  @media screen and (max-width: 768px) {
    flex-direction: column;

    .radio {
      margin-left: 0px;
    }
  }
}

.p-promotionMessageUserList {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 12px 16px;
}

.p-promotionMessageUserList__row {
  background-color: #ffffff;
  border-bottom: 1px solid #f5f5f5;
}

.promotionMessageNote--atSearchButton {
  font-size: 14px;
  display: block;
}

.p-promotionMessageNote--atDeliverToProducer {
  font-size: 14px;
  .p-promotionMessageNote__linkText {
    text-decoration: underline;
  }
}

.p-promotionMessageNote--confirm {
  font-size: 12px;
  color: #f57a00;
}

// Components

.p-promotionMessageStatus {
  padding: 6px 8px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #363636;
}

.p-promotionMessageStatus__id1 {
  background-color: #209cee;
  color: #ffffff;
}

.p-promotionMessageStatus__id2 {
  background-color: #48c774;
  color: #ffffff;
}

.p-promotionMessageStatus__id3 {
  background-color: #b01f25;
  color: #ffffff;
}

.p-promotionMessageTooltip__wrap {
  display: flex;
  align-items: center;

  // stylelint-disable selector-class-pattern
  .tooltip-hint {
    width: 20px;
  }
  // stylelint-enable selector-class-pattern
}

.p-promotionMessageMonthlyUsage__section {
  padding: 16px 24px 0;
}

.p-promotionMessageMonthlyUsage__wrap {
  padding: 16px 16px 0;
  border-top: 1px solid #dbdbdb;

  &:first-child {
    border-top: none;
  }
}
