.p-boostProductScheduleCount {
  padding: 12px 0;
}

.p-boostProductScheduleCount__title {
  display: flex;
  justify-content: center;
  text-align: center;
}

.p-boostProductScheduleCount__navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 12px 0;
}

.p-boostProductScheduleCountTable {
  padding: 12px 0;
}
