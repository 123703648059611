.profile-columns,
.register_account_producer {
  .is-checkradio[type="checkbox"] {
    &:checked[readonly] {
      cursor: not-allowed;
    }
    &[readonly] {
      cursor: not-allowed;
      &:hover,
      &:hover:before,
      &:hover::before {
        animation-name: none;
      }
      &::before,
      &:before,
      &::after,
      &:after {
        cursor: not-allowed;
      }
      & + label {
        cursor: not-allowed;
        &:hover,
        &:before,
        &::before,
        &:hover:before,
        &:hover::before,
        &::after,
        &:after {
          cursor: not-allowed;
        }
      }
    }
  }
}
