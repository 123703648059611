.p-product__submitButtons {
  display: flex;
  padding-bottom: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
}

.p-product__draftButton {
  @media screen and (min-width: 769px) {
    margin-right: 20px;
  }
}

.p-product__fixedSubmitForm {
  // モバイルの場合すでにNavbarが存在しているため、その上に固定する
  @media screen and (max-width: 768px) {
    margin-bottom: 40px !important;
  }
}

.p-product__specifiedIngredientBlock {
  max-height: 0;
  transition: max-height 0.4s ease-out;
  overflow: hidden;
  &.is-open {
    max-height: 100%;
  }
}

.p-product__specifiedIngredientCollapseWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-product__specifiedIngredientCollapse {
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  color: #7a7a7a;
  gap: 4px;
}

.p-products__specifiedIngredientCollapseText {
  margin-right: 4px;
}

.p-products__specifiedIngredientCheckboxContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 8px;
}
