/* stylelint-disable selector-class-pattern -- bulma update に関する修正のため、今は対応しない */
.has-text-red {
  color: $red;
}

.has-text-danger-mark-red {
  color: #af1e25;
}
.has-text-danger-red {
  color: #c30d23;
}

.content {
  &.is-noliststyle {
    ul {
      margin-left: 0.5em;
      list-style: none;
      mark ul {
        list-style: none;
        ul {
          list-style: none;
        }
      }
    }
    ol {
      margin-left: 0;
      list-style: none;
      ol {
        list-style: none;
        ol {
          list-style: none;
        }
      }
    }
  }
}
.modal-card-head {
  padding: 15px 10px;
  @media screen and (min-width: 769px) {
    padding: 20px;
  }
}
.modal-card-title {
  &.is-multiple-lines {
    flex-shrink: 1;
    white-space: nowrap;
    line-height: 1.35;
  }
}

.modal-card-body {
  &.is-haccp {
    max-height: calc(100vh - 340px);
  }
}

.container {
  &.is-fullwidth {
    width: 100%;
    max-width: none;
  }
}

// サイドバーを右揃えにするためにスペース削除
.menu {
  .menu-list {
    ul {
      margin-right: 0;
    }
  }
}

// dropdown
.dropdown-content {
  .dropdown-item {
    &.button {
      border: none;
    }
  }
}

// checkboxが小さすぎるので広げる
.is-checkradio[type="checkbox"] + label::after {
  border-width: 0.2rem !important;
  width: 10px !important;
  height: 14px !important;
  top: 3px !important;
  left: 7px !important;
}

// helper
.is-sub {
  background-color: $white-ter !important;
  color: #fff;
}

.is-paddingless-mobile {
  @media screen and (max-width: 768px) {
    padding: 0 !important;
  }
}

.is-marginless-mobile {
  @media screen and (max-width: 768px) {
    margin: 0 !important;
  }
}

.is-border-top {
  border-top: $border-style;
}

.is-border-bottom {
  border-bottom: $border-style;
}

.is-border-updown {
  border-top: $border-style;
  border-bottom: $border-style;
}

.is-logo img {
  max-height: 2.25rem !important;
}

.is-title {
  font-weight: bold;
  background: $grey-lighter;
  border-left: 3px solid;
}

.is-title-danger {
  font-weight: bold;
  background: $danger;
  color: $white;
  span:first-child {
    padding-left: 0.5rem;
    border-left: 3px solid;
  }
}

.is-title-success {
  font-weight: bold;
  background: $success;
  color: $white;
  span:first-child {
    padding-left: 0.5rem;
    border-left: 3px solid;
  }
}

.is-title-warning {
  font-weight: bold;
  background: $warning;
  color: rgba(0, 0, 0, 0.7);
  span:first-child {
    padding-left: 0.5rem;
    border-left: 3px solid;
  }
}

.is-label {
  border-left: 3px solid #b99b6b;
}

.is-nowrap {
  white-space: nowrap;
}

.is-preline {
  white-space: pre-line;
}

.is-borderless {
  border: none !important;

  & td {
    border: none !important;
  }
}

.is-prewrap {
  white-space: pre-wrap;
}

.is-flex {
  &.is-center {
    align-items: center;
  }
}

.is-justfy-content {
  &.center {
    justify-content: center;
  }
  &.end {
    justify-content: flex-end;
  }
  &.start {
    justify-content: flex-end;
  }
  &.space-between {
    justify-content: space-between;
  }
}

.date {
  color: $topicColor;
}

.topic {
  color: $topicColor;
}

.field-value {
  padding-top: 6px;
}

// bulma override
.field:not(:last-child) {
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #f0f1f4;
}

/* shield */
.full-size-field {
  overflow: scroll;
  position: fixed;
  top: 57px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
}

.high-zindex-dropdown {
  z-index: 9999;
}

/* tag override */
.tag {
  &.alert {
    background-color: #af1e25;
    color: $white;
  }
}

.tabs {
  &.is-smaller {
    font-size: 14px;
  }
}

.is-fixed-top:not(.navbar) {
  position: sticky;
  top: 0;
  z-index: 2;
}

/* 0.7.2 → 0.9.4 アップグレードでデザインが変更になっているので調整 */
.box,
.card,
.panel,
.dropdown-content {
  box-shadow:
    0 2px 3px rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 10%);
  border-color: #fff;
}

.box {
  border-radius: 8px;
}

a.icon-text {
  color: #369;
  &:hover,
  &:active {
    color: #363636;
  }
  &:visited,
  &:link {
    color: #369;
  }
}

@media screen and (min-width: 1024px) {
  .section {
    padding: 3rem 1.5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .container.is-fluid {
    padding-left: 16px;
    padding-right: 16px;
  }
  main .fm-lead {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.is-noflex {
  flex: none;
}

.container.is-fluidOld {
  margin-left: 1rem;
  margin-right: 1rem;
  padding-left: 0;
  padding-right: 0;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
}

.container.p-l-sm {
  padding-left: 0.5rem;
}

.panel.is-shadowless {
  box-shadow: none;
}

main {
  .column {
    &:last-child {
      &.fm-lead {
        padding-right: 0.75rem;
      }
    }
  }
}
