.c-bulkAcceptRow {
  .c-bulkAcceptRow__textarea {
    font-size: 9px;
    width: 100%;
  }
  .c-bulkAcceptRow__textarea--readonly {
    font-size: 9px;
    width: 100%;
    border: none;
  }
}
