.p-reel {
  .p-thumbnailImage {
    max-width: 80px;

    > img {
      display: block;
    }
  }

  .media {
    padding: 16px 0;
    border-top: 1px solid rgba(219, 219, 219, 0.5);

    + .media {
      margin-top: 0;
    }

    &:last-child {
      border-bottom: 1px solid rgba(219, 219, 219, 0.5);
    }
  }

  .p-title {
    font-size: 22px;
    font-weight: bold;
    border-left: 3px solid;
    padding: 0.5rem;
  }

  .p-videoContainer {
    max-width: 300px;
  }
}

.p-4-mobile {
  @media screen and (max-width: 769px) {
    padding: 16px;
  }
}
