.p-loyalUserMessage {
  background-color: #f5f1eb;
  padding: 12px;
  max-width: 400px;
  margin: auto;

  .p-loyalUserMessage__image {
    border: 8px solid #fff;
    border-radius: 2px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.07);
    margin-bottom: 8px;
    width: 100%;
  }

  .p-loyalUserMessageBlock {
    background: #fff;
    padding: 16px;
    border-radius: 17px;
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .p-loyalUserMessageBlock:before {
    content: "";
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -10px;
    border: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }

  .p-loyalUserMessageBlock__title {
    text-align: center;
    padding-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
  }

  .p-loyalUserMessageBlock__message {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 15px;
  }
}
