.p-productSalesStatus__salesStatusOptions {
  display: flex;
  gap: 12px;
  padding: 0.5rem;
}

.p-productSalesStatus__deliveryAvailabilityOptions {
  padding: 0.5rem;
}

.p-productSalesStatus__limitedPublished {
  margin-bottom: 8px;
}

.p-productSalesStatus__annualDelivery {
  margin-bottom: 16px;
}

.p-productSalesStatus__deliveryPeriodContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.p-productSalesStatus__deliveryPeriodRow {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.p-productSalesStatus__deliveryPeriodCell {
  flex: 1;

  &:first-child,
  &:last-child {
    flex: 6;

    @media screen and (max-width: 768px) {
      flex: 2;
    }
  }
}

.p-productSalesStatus__deliveryPeriodLabel {
  font-size: 14px;
}

.p-productSalesStatus__statusTags {
  display: flex;
  justify-content: flex-end;

  & > :first-child {
    margin-right: 4px;
  }
}

.p-productSalesStatus__deliveryPeriodSeparator {
  padding: 0 8px;

  @media screen and (min-width: 769px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    position: relative;
    text-align: center;
    top: 10px;
  }
}
