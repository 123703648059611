.tag-group {
  .tag {
    margin-right: 4px;
  }

  &.small-padding-tag {
    margin-bottom: 7px;

    .tag {
      padding: 3px 4px;
      margin-right: 2px;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.tag:not(body).waiting-for-shipment-request {
  color: #c8a800;
  background-color: white;
  font-weight: bold;
  border: 2px solid #c8a800;
}

.tag:not(body).waiting-for-shipment {
  color: #22b24c;
  background-color: white;
  font-weight: bold;
  border: 2px solid #22b24c;
}
