// list
.item {
  padding: 20px 5px 20px 5px;

  .item-right {
    align-self: center;
    margin-left: 0 !important;
  }

  table td {
    border: none !important;
    padding: 0.25em 0 !important;
  }

  dl {
    dt {
      float: left;
      clear: left;
      margin-right: 0.5em;
      width: 5rem;
      color: $grey;
    }
    dd {
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .dt--semiWide {
    dt {
      width: 7rem;
    }
  }

  dl.dt-flexible {
    dt,
    dd {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 0.5em;
    }
    dt {
      margin-right: 0.25em;
      width: auto;
    }
  }

  .split-line {
    display: inline-block;
    li {
      text-align: center;
    }
    li + li {
      &::before {
        color: #b5b5b5;
        content: "\0002f";
      }
    }
  }
}

.termsList__childList > li {
  list-style: none;
}
