.p-spMenu__bottomNavigationItem {
  flex: 1;
}

.p-spMenu__menuHeader {
  font-size: 22px;
  padding-top: 12px;
  margin: auto 16px 20px;
}

.p-spMenu__menuList {
  list-style-type: none;
}

.p-spMenu__menuList li {
  font-size: 16px;
  padding: 8px 12px;
  border-bottom: 0.5px solid #f5f5f5;
  background-color: $white;
}

.p-spMenu__menuList li.p-spMenu__menuListHeader {
  font-size: 13px;
  font-weight: bold;
  padding: 4px 12px;
  color: $grey;
  background-color: #f5f5f5;
}

.p-spMenu__menuListLogout {
  border-top: 1px solid #f5f5f5;
  margin-top: 24px;
  padding-top: 20px;
}

.p-spMenu__icon {
  vertical-align: middle;
  margin-right: 4px;
}

.p-promotionMenu__description {
  font-size: 14px;
  margin: 0 16px 20px 16px;
}

.p-promotionMenu__noData {
  background-color: #f5f5f5;
  padding: 36px 16px;
  text-align: center;
}

.p-promotionMenu__noDataMessage {
  font-size: 16px;
  color: #7a7a7a;
  margin-bottom: 12px;
}

.p-promotionMenu__contentDescription {
  font-size: 13px;
  line-height: 16.9px;
  color: #7a7a7a;
}

.p-promotionMenu__recipes {
  background-color: $white;
}

.p-promotionMenu__recipe {
  display: flex;
  padding: 20px 16px;
  border-bottom: 1px solid #f5f5f5;
}

.p-promotionMenu__recipeImage {
  width: 44px;
  height: 44px;
}

.p-promotionMenu__recipeName {
  margin-left: 8px;
  font-size: 14px;
}

.p-promotionMenu__contentFooter {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  align-items: center;

  &::after {
    content: "";
    height: 8px;
    width: 8px;
    transform: rotate(45deg);
    border-top: 1px solid #336699;
    border-right: 1px solid #336699;
  }
}

.p-promotionMenu__contentDetailUrl {
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
}

.p-promotionMenu__sameProducts {
  background-color: $white;
}

.p-promotionMenu__sameProduct {
  padding: 12px 16px;
  border-bottom: 1px solid #f5f5f5;
}

.p-promotionMenu__goodsQuantity {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-top: 12px;
}

.p-promotionMenu__goodsQuantityList {
  font-size: 14px;
  color: #7a7a7a;
}

.p-promotionMenu__boostProductSchedule {
  padding: 20px 16px;
  border-bottom: 1px solid #f5f5f5;
}

.p-promotionMenu__boostProductStatus {
  margin-bottom: 12px;
}

.p-promotionMenu__boostProducts {
  background-color: $white;
}

.p-promotionMenu__boostProduct {
  display: flex;
}

.p-promotionMenu__boostProductContainer {
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-rows: auto auto;
  gap: 8px;
  align-items: center;
}

.p-promotionMenu__boostProductImage {
  width: 44px;
  height: 44px;
  align-self: start;
}

.p-promotionMenu__boostProductName {
  font-size: 14px;
  align-self: start;
}

.p-promotionMenu__boostProductHeading {
  color: #7a7a7a;
  font-size: 14px;
}

.p-promotionMenu__pickupProduct {
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-rows: auto auto;
  gap: 8px;
  padding: 20px 16px;
  border-bottom: 1px solid #f5f5f5;
  align-items: center;
  background-color: $white;
}

.p-promotionMenu__pickupProductWordHeading {
  color: #7a7a7a;
  font-size: 14px;
}

.p-promotionMenu__pickupProductWord {
  font-size: 16px;
}

.p-promotionMenu__pickupProductImage {
  width: 44px;
  height: 44px;
  align-self: start;
}

.p-promotionMenu__pickupProductName {
  font-size: 14px;
  align-self: start;
}

.p-promotionMenu__section {
  margin: auto 16px;
}

.p-promotionMenu__businessPartner {
  display: flex;
  gap: 16px;
  padding: 16px;

  &.p-promotionMenu__businessPartner--spOnlycolumn {
    flex-direction: row;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &.p-promotionMenu__businessPartner--column {
    flex-direction: column;
  }
}

.p-promotionMenu__businessPartnerImages {
  &.p-promotionMenu__businessPartnerImages--spOnlyTextalignCenter {
    text-align: left;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
}

.p-promotionMenu__businessPartnerImage {
  min-width: 200px;
  max-width: 200px;
  @media screen and (max-width: 768px) {
    min-width: 100px;
  }
}
