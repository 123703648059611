.c-announcementNotification {
  &.is-fluid {
    padding-top: 16px;
    @media screen and (max-width: 768px) {
      padding: 16px;
      margin-bottom: 24px;
    }
  }
}

.c-announcementNotification__layout {
  margin: 0;
  display: grid;
  gap: 8px;

  .c-announcementNotification__layout--item {
    padding: 0;
  }
}

.c-announcementNotification__fluid {
  @media screen and (max-width: 768px) {
    padding: 0 !important;
  }
}

.c-announcementNotification__columns {
  @media screen and (max-width: 768px) {
    margin: 0;
  }
}

.c-announcementNotification__item {
  margin: 5px;
  background-color: $white;
  border: 1px solid #dbdbdb;
  border-radius: 8px;

  .c-announcementNotification__content {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    @media screen and (max-width: 768px) {
      padding: 16px 8px;
    }

    .c-announcementNotification__content--date {
      margin-right: 16px;
    }

    @media screen and (max-width: 768px) {
      margin: 0 12px;

      .c-announcementNotification__content--date {
        display: block;
        font-size: 12px;
      }
    }

    .c-announcementNotification__content--link {
      font-weight: bold;

      &:active,
      &:link,
      &:visited {
        color: $blue;
      }

      &:hover {
        opacity: 0.8;
        color: $blue;
      }

      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }

    .c-announcementNotification__content--deleteButton {
      display: flex;
      align-items: center;
    }
  }
}
