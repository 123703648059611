/* /loading shield */

/*  producer */
.inner-main-content {
  font-family:
    Hiragino Kaku Gothic Pro,
    \\30d2\30e9\30ae\30ce\89d2\30b4 Pro W3,
    Meiryo,
    メイリオ,
    Osaka,
    MS PGothic,
    arial,
    helvetica,
    sans-serif;
  padding: 0;
}

.supply-content {
  .image-area {
    float: none;
    width: 100%;

    .main-image {
      width: 100%;
    }
  }

  .page-supply {
    float: none;
    width: 100%;
    padding: 0;

    .page-supply-inner {
      margin-top: -21%;
      padding: 0;
    }
  }

  .supply-info-area {
    .profile {
      overflow: hidden;
      display: block;
      padding: 20px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .thumbs {
        width: 30.5%;
        margin: 0 auto 3px;
        border: 3px solid #fff;
        overflow: hidden;
        border-radius: 50%;
        img {
          width: 100%;
          height: auto;
          vertical-align: top;
        }
      }

      .text-block {
        text-align: center;
        .place {
          font-size: 14px;
        }
        .sup-name {
          margin-bottom: 3px;
          font-size: 18px;
          font-weight: 700;
        }
      }
    }

    .sup-desc {
      padding: 20px;
      font-size: 13px;
      border-top: 1px solid #e8e8f3;
    }
  }
}

.sup-intro {
  .inner-main-content {
    padding: 0;

    .intro-wrap {
      padding: 10px 0 30px;
      text-align: left;

      .intro-block {
        display: block;
        width: 100%;
        vertical-align: top;
        text-align: left;

        .intro-img {
          padding: 15px;
        }

        .intro-ttl {
          margin-bottom: 15px;
          padding: 0 15px;
          font-size: 18px;
          font-weight: 700;
          color: #aa7f4d;
        }

        .intro-text {
          padding: 0 15px 15px;
          font-size: 13px;
        }
      }
    }
  }

  h2 {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 700;
    background: #f5f1eb;
  }
}

/* products */
.product-content {
  .image-area {
    float: none;
    width: 100%;
    margin-right: 0;

    .image-area-inner {
      padding: 0 20px 20px;
      background: #f5f5f5;

      .main-image {
        width: 100%;
        margin-bottom: 5px;
        -webkit-box-shadow: 0 5px 10px #ededed;
        box-shadow: 0 5px 10px #ededed;

        img {
          width: 100%;
          height: auto;
          vertical-align: top;
        }
      }

      .thumbs-list {
        letter-spacing: -0.4em;
        text-align: center;

        li {
          display: inline-block;
          max-width: 50px;
          width: 15%;
          margin-top: 10px;
          margin-right: 15px;
          cursor: pointer;

          img {
            width: 100%;
            height: auto;
            vertical-align: top;
          }
        }
      }
    }
  }

  .info-area {
    width: 100%;
    padding: 20px;

    .info-block {
      padding: 0;

      .item-name {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 700;
      }

      .delivery {
        font-size: 12px;
        margin-bottom: 20px;
      }

      .products-order {
        padding-left: 0;
      }

      .price {
        margin-bottom: 10px;
        font-size: 24px;

        .yen {
          padding-left: 3px;
          font-size: 14px;
        }

        .tax {
          font-size: 10px;
        }
      }

      .btn-buy {
        margin: 30px auto;
        width: 250px;
        text-align: center;
        a {
          display: block;
          width: 100%;
          padding: 13px;
          color: #fff;
          background: #cab270;
          border-radius: 4px;
        }
      }
    }

    .description {
      padding: 0 0 20px;
      line-height: 1.6;
      font-size: 13px;

      h3 {
        font-size: 15px;
        font-weight: 700;
        color: #aa7f4d;
      }
    }
  }
}

.supply-info-area {
  .inner-main-content {
    padding: 0 0 30px;
    text-align: left;
  }

  .supply-info-area {
    .sup-title {
      padding: 12px 20px;
      font-size: 16px;
      font-weight: 700;
      background: #f5f1eb;
    }

    .profile {
      overflow: hidden;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 20px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .thumbs {
        overflow: hidden;
        width: 25%;
        margin-right: 15px;
        border-radius: 50%;
        img {
          width: 100%;
          height: auto;
          vertical-align: top;
        }
      }

      .text-block {
        .place {
          font-size: 14px;
        }
        .sup-name {
          font-size: 18px;
          font-weight: 700;
        }
      }
    }

    .sup-desc {
      padding: 20px;
      font-size: 13px;
      border-top: 1px solid #e8e8f3;
    }
  }
}
