@media screen and (min-width: 769px) {
  .field-label {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 2;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 1.5rem;
    text-align: left;
  }
}

.label {
  font-size: 15px;
}
.field-label .label {
  font-size: 15px;
}
