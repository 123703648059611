.dialog {
  display: none;
  background: $white;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 40;
}

.dialog.is-active {
  display: block;
}

.dialog_content,
.dialog_footer {
  position: absolute;
  width: 100%;
}
.dialog_content {
  bottom: 100px;
  top: 62px;
  overflow-y: auto;

  textarea {
    left: 0;
    top: 0;
    right: 0;
    bottom: 100px;
    width: 100%;
    border: none !important;
  }
  .textarea:active,
  .textarea.is-active {
    border-color: none;
    box-shadow: none;
  }
}
.dialog_footer {
  bottom: 0;
}
