.p-producerGuidelines__title {
  font-size: 26px;

  @media screen and (max-width: 768px) {
    padding: 16px;
    font-size: 22px;
  }
}

.p-producerGuidelines__description {
  font-size: 16px;
  padding: 12px 0 20px 0;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 0 16px 16px 16px;
  }
}

.p-producerGuidelines__categoryContainer {
  padding: 20px 32px;
  background: #fff;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    padding: 16px;
    margin-bottom: 16px;
  }
}

.p-producerGuidelines__categoryTitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.p-producerGuidelines__guidelineContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    column-gap: 0;
  }
}

.p-producerGuidelines__guidelineItem {
  border-bottom: 1px solid #dbdbdb;
  padding: 12px 0;
  break-inside: avoid-column;
}

.p-producerGuidelines__guidelineTitle {
  font-size: 16px;
  text-decoration: underline;
  color: #336699;
}

.p-producerGuideline__toTopLinkWrapper {
  display: flex;
}

.p-producerGuideline__toTopLink {
  display: block;
  font-size: 16px;
  text-decoration: underline;
  color: #336699;

  @media screen and (max-width: 768px) {
    padding: 16px 16px 0 16px;
  }

  &::before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    position: relative;
    transform: rotate(135deg);
    top: 15px;
    border: solid #336699;
    border-width: 0 2px 2px 0;
  }
}

.p-producerGuideline__toTopLinkText {
  padding: 0 8px;
}

.p-producerGuideline__categoryTitle {
  padding: 18px 0 12px 0;
  font-size: 26px;

  @media screen and (max-width: 768px) {
    padding: 20px 16px 12px 16px;
    font-size: 22px;
  }
}

.p-producerGuideline__categoryDescription {
  font-size: 16px;
  line-height: 27.2px;
  padding-bottom: 24px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 23.8px;
    padding: 0 16px 16px 16px;
  }
}

.p-producerGuideline__Container {
  background: #fff;
  padding: 32px;
  margin-bottom: 40px;
  scroll-margin-top: 50px;

  @media screen and (max-width: 768px) {
    padding: 32px 16px;
    margin-bottom: 20px;
  }
}

.p-producerGuideline__title {
  font-size: 24px;
  font-weight: 700;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}

.p-producerGuideline__content {
  font-size: 18px;
  line-height: 34.2px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 30.4px;
  }
}

.p-producerGuideline__pointWrapper {
  padding: 32px 0;
}

.p-producerGuideline__pointContent {
  border: 2px solid #209cee;
  padding: 24px;
  font-size: 18px;
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.p-producerGuideline__pointIcon {
  margin-right: 4px;
}
.p-producerGuideline__pointTitle {
  clear: both;
  float: left;
  background: #209cee;
  color: #fff;
  font-weight: bold;
  padding: 0px 7px 0px 9px;
  margin-top: -10px;
  border-radius: 4px;
  font-size: 16px;
}
