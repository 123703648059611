.featuredImage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 12px;

  .featuredImage__label {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #363636;
  }
}
