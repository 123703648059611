/* Modal Component ---------------------- */
.modal {
  position: fixed !important;

  .modal-content {
    margin: 0 20px;
    max-height: unset;
    overflow: auto;
    position: relative;
    width: 100%;

    @media screen and (min-width: 769px) {
      margin: 0 auto;
      width: 640px;
    }

    .photo {
      display: flex;
      background-color: #fff;
      img {
        width: 100%;
      }
    }

    img {
      max-width: 100%;
      position: relative;

      &:hover ~ .navigation {
        @extend .flexbox;
      }
    }

    .navigation {
      position: absolute;
      color: white;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: none;
      justify-content: space-between;
      align-items: center;

      &:hover {
        @extend .flexbox;
      }
    }
  }
}

.modal-content_swiper > .swiper-wrapper {
  align-items: center;
}
.modal-content_slide {
  padding-bottom: 40px;
}
.modal-close {
  z-index: 999;
}
/* ---------------------- Modal Component */
