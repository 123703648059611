// review && feedback
.valuation {
  color: #d0bb9a;
}

.review {
  &:not(:first-child) {
    border-top: 1px solid rgba(219, 219, 219, 0.5);
  }
  .media + .media {
    border: 0 !important;
  }
}
