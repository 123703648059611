////////////////////////////////////////////////
// JOURNAL
////////////////////////////////////////////////
$white: hsl(0, 0%, 100%);
$grey-darker: #363636;
$grey-dark: #4a4a4a;
$grey: #7a7a7a;
$grey-light: #b5b5b5;
$grey-lighter: #dbdbdb;

$orange: #f57a00;
$yellow: #f5e625;
$green: #22b24c;
$turquoise: #00aba9;
$blue: #369;
$purple: #aa40ff;
$red: #ff2e12;

$custom-colors: (
  "red": (
    $red,
    $white,
  ),
  "new": (
    $red,
    $white,
  ),
);

$primary: $blue !default;
$danger: $orange;

$family-heading: "News Cycle", "Arial Narrow Bold", sans-serif;

$radius: 4px;

$navbar-item-hover-color: $primary;
$navbar-item-active-color: $primary;
$navbar-dropdown-item-hover-color: $primary;
$navbar-dropdown-item-active-color: $primary;

$bulmaswatch-import-font: true !default;
