.image-gallery {
  padding: 0.5vw;
  font-size: 0;
  flex-flow: row wrap;
  display: flex;

  div {
    flex: auto;
    position: relative;
    width: 200px;
    margin: 0.1vw;
    img {
      width: 100%;
      height: auto;
    }
    .delete {
      @extend .delete-btn;
    }
  }
}

@media screen and (max-width: 400px) {
  .image-gallery {
    padding: 0;
    div {
      margin: 0.5vw;
    }
  }
}

.image-gallery {
  width: 100%;
  font-size: 0;
  @extend .flexbox;
  -ms-flex-wrap: wrap;
  -ms-flex-direction: column;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;

  .image {
    -webkit-box-flex: auto;
    -ms-flex: auto;
    flex: auto;
    width: 200px;
    margin: 0.1vw;
    img {
      width: 100%;
      height: auto;
    }

    &:last-child {
      position: relative;
      display: inline-block;
      overflow: hidden;
      vertical-align: middle;

      .view-all {
        text-decoration: none;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        &::before {
          display: inline-block;
          content: "";
          vertical-align: middle;
          height: 100%;
        }

        .view-all-cover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: black;
          opacity: 0.4;
        }
        .view-all-text {
          position: relative;
          font-size: 16px;
          font-family: sans-serif;
          color: white;
        }
      }
    }
  }
}
