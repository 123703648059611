#farmer-error-snackbar {
  visibility: visible;
  min-width: 250px;
  background-color: $black;
  color: $white;
  text-align: center;
  border-radius: 2px;
  padding: 20px 16px;
  position: fixed;
  z-index: 100;
  bottom: 30px;
  left: calc(50%);
  transform: translate(-50%, -50%);
  white-space: pre-wrap;

  @media screen and (max-width: 1023px) {
    width: 80%;
  }
}

#farmer-info-snackbar {
  visibility: visible;
  min-width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 20px 16px;
  position: fixed;
  z-index: 100;
  bottom: 30px;
  left: calc(50% - 250px / 2);
  white-space: pre-wrap;

  @media screen and (max-width: 1023px) {
    right: 30px;
    margin: 0;
  }
}
